var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMounted),expression:"isMounted"}],attrs:{"min-height":"500","id":"page__home"}},[_c('v-row',{class:{ 'mx-0 mt-0 pt-0 ': !_vm.$vuetify.breakpoint.mobile }},[_c('div',{staticClass:"justify-center align-center mx-auto text-center py-1"},[_c('h1',[_vm._v("Welcome to MyUSE")])]),_vm._v(" "),_c('v-col',{staticClass:"px-0",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"card__text rounded-xl mx-4",class:{
          'elevation-24 py-5 myuse-border': !_vm.$vuetify.breakpoint.mobile,
        }},[_c('v-row',{class:{ 'mx-0': _vm.$vuetify.breakpoint.mobile }},[_c('v-col',[_c('v-img',{staticClass:"embed-image index-header",attrs:{"src":require('~/assets/images/header.svg'),"alt":"","min-height":"150px"}})],1)],1),_vm._v(" "),_c('v-card-text',[_c('v-row',{staticClass:"ma-0",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"10","xs":"12"}},[_c('nuxt-content',{staticClass:"pb-0",attrs:{"document":_vm.page}}),_vm._v(" "),(_vm.isPWA)?_c('div',[_vm._v("\n                You are running this app in standalone mode. Your session will\n                be stored on this device.\n              ")]):_vm._e(),_vm._v(" "),_c('v-row',{staticStyle:{"z-index":"99"},attrs:{"justify":"center"}},[_c('v-col',{staticClass:"px-2 py-2",attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){return [(
                          _vm.isPWA == false || (_vm.isTesting && _vm.show_confirm_age)
                        )?_c('v-switch',{attrs:{"id":"remember-me","input-value":_vm.remember_me,"false-value":0,"true-value":1,"label":"Remember me"},on:{"change":function($event){return _vm.changedRememberMe()}}}):_vm._e()]}}])},[_vm._v(" "),_c('span',[_vm._v("Click this to save your session on this computer for\n                      the next 30 days")])])],1),_vm._v(" "),_c('v-col',{staticClass:"px-2 pt-2 pb-",attrs:{"cols":"auto"}},[_c('transition',{attrs:{"name":"login-fade","tag":"div"}},[_c('v-checkbox',{directives:[{name:"show",rawName:"v-show",value:(_vm.show_confirm_age),expression:"show_confirm_age"}],staticClass:"age-confirm",staticStyle:{"display":"inline-flex"},attrs:{"id":"age-confirm","checked":_vm.age_confirmed,"input-value":_vm.age_confirmed,"disabled":(_vm.has_completed_capthca || _vm.user !== null) &&
                        _vm.age_confirmed,"false-value":undefined || false,"true-value":true || 'true'},on:{"click":function($event){return _vm.confirmAge()}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(" I am over 18")])]},proxy:true}]),model:{value:(_vm.age_confirmed),callback:function ($$v) {_vm.age_confirmed=$$v},expression:"age_confirmed"}})],1)],1)],1),_vm._v(" "),_c('v-row',{staticClass:"ma-0 pa-3 pt-0",attrs:{"justify":"center"}},[_c('transition',{attrs:{"name":"fade"}},[_c('client-only',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"auto"}},[(!_vm.captcha_content)?_c('div',{staticClass:"text-center pa-5"},[_c('v-progress-circular',{staticStyle:{"max-width":"200px"},attrs:{"indeterminate":"","aria-label":"loading-circle","color":"primary"}})],1):(
                          _vm.show_captcha && _vm.captcha_content && _vm.isTesting == false
                        )?_c('img',{staticClass:"pt-1 pb-1",staticStyle:{"max-width":"300px"},attrs:{"src":_vm.captcha_content,"height":"100px"}}):_vm._e()])],1)],1)],1),_vm._v(" "),_c('transition',[_c('v-row',{staticClass:"text-center justify-center py-0 pb-3 pt-1",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-2 pt-1",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"id":"renew-captcha","disabled":_vm.has_completed_capthca || _vm.user !== null,"fab":"","text":"","aria-label":"renew-captcha","small":""},on:{"click":_vm.generateCaptcha}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-autorenew")])],1)],1),_vm._v(" "),_c('v-col',{staticClass:"pb-3",attrs:{"cols":"auto"}},[_c('v-text-field',{staticClass:"text-center justify-center py-0",staticStyle:{"max-width":"200px"},attrs:{"disabled":_vm.has_completed_capthca || _vm.user !== null,"maxlength":5,"dense":"","filled":"","placeholder":!_vm.user ? 'Enter the code' : '',"rules":[
                        function (v) { return !!v || 'You must complete the captcha.'; } ],"clearable":"","outlined":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.blurButton($event)},"blur":function($event){return _vm.registerUser()}},model:{value:(_vm.captcha_code),callback:function ($$v) {_vm.captcha_code=$$v},expression:"captcha_code"}})],1)],1)],1),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[_c('v-row',{staticClass:"ma-0",attrs:{"justify":"center"}},[_c('v-btn',{attrs:{"id":"register","disabled":!_vm.allow_begin,"color":"primary","aria-label":"go-back"},on:{"click":function($event){return _vm.$store.dispatch('navigation/goToMenu')}}},[_vm._v("\n                    Begin\n                  ")])],1)],1)],1)],1)],1)],1)],1)],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"hidden"},[_c('label',{staticClass:"sr-only"},[_vm._v("Don’t fill this out if you're human: ")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.bot),expression:"bot"}],attrs:{"aria-hidden":"true","name":"name","placeholder":""},domProps:{"value":(_vm.bot)},on:{"input":function($event){if($event.target.composing){ return; }_vm.bot=$event.target.value}}})]),_vm._v(" "),(_vm.show_revisit)?_c('v-btn',{attrs:{"id":"show-revisit","fab":"","dark":"","color":"primary","fixed":"","left":"","aria-label":"revisit-button","to":"privacy","bottom":""}},[_c('v-icon',{staticStyle:{"font-size":"42px"}},[_vm._v("mdi-shield-check ")])],1):_vm._e(),_vm._v(" "),(_vm.show_revisit)?_c('revisit-dialog',{ref:"dialog",attrs:{"user":_vm.user}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }