//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import RevisitDialog from '~/components/lazyloads/dialogs/RevisitDialog.vue'
export default {
  name: 'Welcome',
  layout: 'layout-landing',
  components: {
    RevisitDialog,
  },
  async asyncData({ $content }) {
    const page = await $content('general/index').fetch()
    return {
      page,
    }
  },
  transition: 'myuse-fade',
  data: () => ({
    page: null,
    isMounted: false,
    show_confirm_age: true,
    allow_begin: false,
    show_captcha: true,
    bot: false,
    batch_id: 0,
    age_confirmed: false,
    isTesting: false,
    remember_me: false,
    has_completed_capthca: false,
    last_login: null,
    show_revisit: false,
    user: null,
    captcha_content: null,
    captcha_key: null,
    captcha_code: null,
  }),
  computed: {
    isPWA() {
      return false
    },
  },
  watch: {
    captcha_code(val) {
      if (val && val.length === 5) {
        setTimeout(() => {
          this.registerUser()
        }, 100)
      }
    },
  },
  mounted() {
    this.remember_me = this.$store.getters['auth/getRememberMeCookieStatus']
    try {
      const converted = window.localStorage.vuex
      const persisted = JSON.parse(converted)
      this.user = persisted.auth.user
      this.last_login = persisted.auth.logged_in_time
      this.age_confirmed = true
      this.allow_begin = true
      this.logoutIfExpired()
    } catch (e) {
      // ...
    }
    if (this.$config.APP_ENV === 'development') {
      this.isTesting = true
      this.batch_id = 99
    }
    this.isMounted = true
  },
  created() {
    this.generateCaptcha()
    this.$nuxt.$on(
      'reloadCookieDisplay',
      (payload) => {
        this.remember_me = this.$store.getters['auth/getRememberMeCookieStatus']
      },
      this
    )
  },
  beforeDestroy() {
    this.$nuxt.$off('reloadCookieDisplay')
  },
  methods: {
    logoutIfExpired() {
      const thirty_days = 1000 * 60 * 60 * 24 * 7
      const Result =
        Math.round(new Date(this.last_login).getTime() - new Date().getTime()) /
        thirty_days
      if (Result < -30 || this.remember_me === false || this.remember_me === "0") {
        this.$store.dispatch('auth/logoutUser')
      } else {
        this.show_revisit = true
      }
    },
    blurButton(event) {
      event.target.blur()
    },
    changedRememberMe() {
      this.remember_me =
        this.remember_me === 1 || this.remember_me === '1' ? 0 : 1
      this.$store.dispatch('auth/setRememberMePreference', this.remember_me)
    },
    generateCaptcha() {
      const self = this
      this.$axios
        .$get(this.$config.BASE_API + '/auth/get_captcha')
        .then((response) => {
          this.captcha_content = response.image
          this.captcha_key = response.key
        })
        .catch(() => {
          self.$dialog.message.success('API error. Please try again later.', {
            position: 'top',
            color: 'red',
            timeout: 3000,
          })
        })
    },
    async registerUser() {
      const self = this
      if (this.user) {
        this.has_completed_capthca = true
        if (this.age_confirmed === true) {
          this.allow_begin = true
        } else {
          self.$dialog.message.success(
            'Captcha entry successful! Please confirm you are over 18.',
            {
              position: 'top',
              color: 'green',
              timeout: 3000,
            }
          )
        }
        return
      }
      if (this.bot) {
        return
      }
      await this.$store
        .dispatch('auth/register_user', {
          batch_id: this.batch_id,
          domain: window.location.hostname,
          key: this.captcha_key,
          captcha: this.captcha_code,
        })
        .then((user) => {
          this.user = user
          this.has_completed_capthca = true
          if (this.age_confirmed === true) {
            self.allow_begin = true
          } else {
            self.$dialog.message.success(
              'Captcha entry successful! Please confirm you are over 18.',
              {
                position: 'top',
                color: 'green',
                timeout: 3000,
              }
            )
          }
        })
        .catch(() => {
          self.$dialog.message.error('Incorrect!', {
            position: 'top',
            color: 'red',
            timeout: 4000,
          })
          self.generateCaptcha()
        })
    },
    async confirmAge() {
      if (this.allow_begin === true) {
        this.allow_begin = false
        return
      }
      if (
        this.$config.APP_ENV === 'development' &&
        this.age_confirmed === true
      ) {
        this.allow_begin = true
        await this.registerUser('TEST MODE')
      } else if (this.age_confirmed === true && this.user) {
        this.allow_begin = true
      }
    },
  },
  head() {
    return {
      htmlAttrs: {
        class: this.$vuetify.breakpoint.mobile ? 'mobile-font' : 'normal-font',
      },
      title: 'Welcome!',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            'MyUSE (My Understanding Substance Use Experiences) is a support and harm-reduction web application, providing support to higher-education students who want to understand their illicit substance use experiences and access relevant resources.',
        },
      ],
      appleStatusBarStyle: 'primary',
    }
  },
}
